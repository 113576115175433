import Icon from "components/Icon"
import If from "components/If"
import { Controller } from "react-hook-form"
import classnames from "classnames"

import useSelect from "./hooks"

import "./Select.styl"

const Select = ({
  children,
  name,
  label,
  className,
  variant = "normal",
  readonly,
  form: { control, setValue },
  placeholder = "Selecione...",
  defaultValue = null,
  onChange = (e) => e,
  ...props
}) => {
  const { options, dropdown, search, setRef } = useSelect({
    name,
    setValue,
    control,
    defaultValue,
    onChange,
  })

  const items = options.filtered.length ? options.filtered : options.all

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { ref, value, ...field } }) => (
        <div
          className={classnames("select", {
            "select--readonly": readonly,
          })}
          tabIndex="-1"
          onFocus={dropdown.openSelect}
          onBlur={dropdown.closeSelect}
        >
          <If
            condition={label}
            renderIf={
              <label className="form__label" htmlFor={name}>
                {label}
              </label>
            }
          />
          <label
            className={classnames(
              "select__field",
              {
                [`select__field--${variant}`]: variant,
              },
              className
            )}
          >
            <input
              className="select__text"
              type="search"
              aria-label="Pesquisar"
              value={search.value}
              placeholder={placeholder}
              onChange={(e) => search.find({ str: e.target.value })}
            />
            <If
              condition={!search.value}
              renderIf={
                <Icon
                  className="select__icon"
                  aria-label="Expandir"
                  name="arrow-down"
                />
              }
              renderElse={
                <Icon
                  name="close"
                  className="select__icon"
                  aria-label="Limpar seleção"
                  onClick={dropdown.cleanSelect}
                />
              }
            />
          </label>
          <div
            className={classnames("select__dropdown", {
              "select__dropdown--visible": dropdown.isOpen,
            })}
            data-test="dropdown"
          >
            <ul className={classnames("select__options")}>
              <If
                condition={items.length}
                renderIf={items.map(({ value, label }) => (
                  <li
                    className="select__item"
                    key={value}
                    onClick={() => {
                      dropdown.chooseOption(value)
                      search.find({
                        str: dropdown.getSelected(options.all, value),
                        shouldFilter: false,
                      })
                    }}
                    aria-label={value}
                  >
                    {label}
                  </li>
                ))}
                renderElse={
                  <li className="select__item--disabled">
                    Nenhuma opção disponível...
                  </li>
                }
              />
            </ul>
          </div>
          <select
            className={classnames(
              "select__input",
              {
                [`select__input--${variant}`]: variant,
              },
              className
            )}
            readOnly={readonly}
            ref={(reference) => {
              ref(reference)
              setRef(reference)
            }}
            {...props}
            {...field}
          >
            {children}
          </select>
        </div>
      )}
    />
  )
}

export default Select
