import { useCallback, useRef, useState, useEffect } from "react"
import { useWatch } from "react-hook-form"
import { buildOptions, isSearchable } from "./utils"

const useSelect = ({ name, control, setValue, defaultValue, onChange }) => {
  const value = useWatch({ control, name, defaultValue })
  const options = useRef([])
  const selectRef = useRef(null)
  const [search, setSearch] = useState("")
  const [filteredOptions, setFilteredOptions] = useState([])
  const [open, setOpen] = useState(false)

  const chooseOption = (option) => {
    document.activeElement.blur()
    setValue(name, option, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    })
  }

  const cleanSelect = (e) => {
    e.preventDefault()
    document.activeElement.blur()

    setValue(name, null)
    setSearch("")

    const target = { ...selectRef.current, value: null }

    onChange({
      target,
      currentTarget: target,
    })
  }

  const setRef = useCallback((reference) => {
    if (reference) {
      const items = buildOptions(reference.children)

      options.current = items
    }

    selectRef.current = reference
  }, [])

  const openSelect = () => setOpen(true)

  const closeSelect = () => setOpen(false)

  const getSelected = (options, value) =>
    options.find((item) => item.value == value)?.label || ""

  const searchFor = ({ str = "", shouldFilter = true }) => {
    const all = options.current

    if (isSearchable(str) && shouldFilter) {
      const test = new RegExp(str, "i")
      const list = all.filter(({ label }) => label.toLowerCase().match(test))

      setFilteredOptions(list)
    } else {
      setFilteredOptions(all)
    }

    setSearch(str)
  }
  useEffect(() => {
    const target = { ...selectRef.current, value: value }

    if (!(value === null)) {
      setSearch(getSelected(options.current, value))
      onChange({
        target,
        currentTarget: target,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return {
    options: {
      all: options.current,
      filtered: filteredOptions,
    },
    dropdown: {
      isOpen: open,
      openSelect,
      cleanSelect,
      closeSelect,
      chooseOption,
      getSelected,
    },
    search: {
      value: search,
      find: searchFor,
    },
    setRef,
  }
}

export default useSelect
