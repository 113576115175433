import "./Typography.styl"

import classnames from "classnames"

const Title = ({ className, children, variant = "medium" }) => (
  <h1
    className={classnames("title", {
      [className]: className,
      [`title--${variant}`]: variant,
    })}
  >
    {children}
  </h1>
)

export default Title
