import Can from "components/Can"
import PageHeader from "components/PageHeader"
import Redirect from "components/Redirect"
import FormEditUser from "./components/FormEditUser"
import { useParameters } from "hooks/useParameters"

const EditUser = () => {
  const {
    user_id: [userId],
    current_page: [currentPage],
  } = useParameters("user_id", "current_page")

  if (!userId) return <Redirect to={ROUTES.home.path} />

  return (
    <Can I="management" the="users">
      <PageHeader title="Editar usuário" />
      <section className="edit-user">
        <FormEditUser userId={userId} currentPage={currentPage} />
      </section>
    </Can>
  )
}

export default EditUser
