import cpfMask from "utils/masks/cpf"
import cnpjMask from "utils/masks/cnpj"
import dateMask from "utils/masks/date"
import cepMask from "utils/masks/cep"
import createNumberMask from "text-mask-addons/dist/createNumberMask.js"
import formatters from "utils/formatters"

const currencyMask = createNumberMask({
  suffix: "",
  prefix: "R$",
  allowNegative: false,
  requiredDecimal: false,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  decimalSymbol: ",",
  allowDecimal: true,
})

const percentMask = createNumberMask({
  prefix: "",
  suffix: "%",
  decimalSymbol: ",",
  allowDecimal: true,
  requiredDecimal: true,
  thousandsSeparatorSymbol: ".",
  includeThousandsSeparator: true,
})

const phoneMask = (value) => {
  const cellphone = [
    "(",
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ]

  const phone = [
    "(",
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ]

  const nums = value && formatters.removeSpecialChars(value).replace(/\s/g, "")

  return nums.length > 10 ? cellphone : phone
}

const documentMask = (value) => {
  return formatters.removeSpecialChars(value).length <= 11 ? cpfMask : cnpjMask
}

export default {
  cpf: cpfMask,
  cep: cepMask,
  date: dateMask,
  cnpj: cnpjMask,
  phone: phoneMask,
  percent: percentMask,
  document: (value) => documentMask(value),
  currency: (value) => currencyMask(value),
}
