import Form from "components/Forms/Form"
import Button from "components/Button"
import Card from "components/Card"
import Input from "components/Forms/Input"
import Select from "components/Forms/Select"
import Switch from "components/Forms/Switch"
import maskers from "utils/maskers"
import useFormEditUser from "./hooks"

import { validation } from "./form"

import "./FormEditUser.styl"

const FormEditUser = ({ userId, currentPage }) => {
  const { values, profiles, companies, businesses, permissions, onSubmit } =
    useFormEditUser({ userId, currentPage })

  const removeUserManagement = (user) => {
    return user.code !== "P000015" && user.code !== "P000001"
  }

  return (
    <Form
      className="form-edit-user"
      onSubmit={onSubmit}
      defaultValues={values}
      schema={validation}
      manually={true}
      reinitialize={true}
    >
      {({ form, state: { isDisabled } }) => (
        <>
          <Card
            className="form-edit-user__card"
            title="Informações de usuário"
            radius="medium"
          >
            <Input
              className="form-edit-user__input"
              label="Nome"
              name="user_name"
              placeholder="Nome"
              data-test="name"
              form={form}
            />
            <Input
              className="form-edit-user__input"
              label="CPF"
              name="document_number"
              data-test="document"
              placeholder="CPF"
              mask={maskers.cpf}
              form={form}
            />
            <Input
              className="form-edit-user__input"
              label="Email"
              type="email"
              name="user_email"
              data-test="email"
              placeholder="Email"
              form={form}
            />
            <Input
              className="form-edit-user__input"
              label="Telefone celular"
              name="phone_number"
              placeholder="Telefone"
              data-test="phone"
              mask={maskers.phone}
              form={form}
            />
            <Input
              className="form-edit-user__input"
              label="ID do agente"
              name="agent_id"
              id="agent_id"
              data-test="agentId"
              placeholder="Opcional"
              form={form}
            />
            <Input
              className="form-add-user__input"
              label="CEP"
              name="postal_code"
              data-test="cep"
              id="postal_code"
              placeholder="Opcional"
              mask={maskers.cep}
              form={form}
            />
          </Card>
          <Card
            className="form-edit-user__card"
            title="Empresa e negócios"
            radius="medium"
          >
            <Select
              className="form-edit-user__input"
              label="Empresa"
              name="group_id"
              data-test="groupId"
              form={form}
              placeholder="Selecione..."
            >
              {companies.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </Select>
            <Select
              className="form-edit-user__input"
              label="Perfil do usuário"
              name="profile_id"
              data-test="profileId"
              form={form}
              placeholder="Selecione..."
            >
              {profiles.map((profile) => (
                <option key={profile.id} value={profile.id}>
                  {profile.name}
                </option>
              ))}
            </Select>
            <ul className="form-edit-user__list">
              {businesses.map((business) => (
                <li key={business.business_id} className="form-edit-user__item">
                  <h1 className="form-edit-user__title">
                    {business.business_name}
                  </h1>
                  <ul className="form-edit-user__list">
                    {business?.permissions.map((permission) => (
                      <li key={permission.code}>
                        <Switch
                          data-test="business-permissions"
                          legend={permission.description}
                          name={String(
                            `business_${business.business_id}_${permission.code}`
                          )}
                          value={String(permission.selected)}
                          form={form}
                        />
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </Card>
          <Card
            className="form-edit-user__card"
            title="Gerenciais"
            radius="medium"
          >
            <ul className="form-edit-user__list">
              {permissions.filter(removeUserManagement).map((global) => (
                <li key={global.code}>
                  <Switch
                    data-test="global-permissions"
                    legend={global.description}
                    name={`global_${global.code}`}
                    value={String(global.selected)}
                    form={form}
                  />
                </li>
              ))}
            </ul>
          </Card>
          <Button
            className="form-edit-user__submit"
            type="submit"
            size="big"
            data-test="submit"
            disabled={isDisabled}
          >
            Salvar
          </Button>
        </>
      )}
    </Form>
  )
}

export default FormEditUser
