const isPhoneValid = (value = "", minLength, maxLength, type) => {
  if (!value) return true

  if (value === null) value = ""
  let onlyNums = value.match(/\d+/g)

  if (!onlyNums) {
    return false
  } else {
    onlyNums = onlyNums.join("")
  }

  if (
    /(\d)\1{7,}/.test(onlyNums.substr(2)) ||
    /^(.)\1+$/.test(onlyNums.substring(2)) ||
    /^0/.test(onlyNums) ||
    (type === "cellphone" && !/^\d{2}[4-9]\d{8}$/.test(onlyNums)) ||
    onlyNums.length < minLength ||
    onlyNums.length > maxLength
  ) {
    return false
  } else {
    return true
  }
}

export default isPhoneValid
