const isChecked = ({ selected, code }) => (selected ? String(code) : "")

export const setBusinessFields = (data) =>
  data.reduce((fields, field) => {
    const newField = field.permissions.reduce((acc, item) => {
      const key = String(`business_${field.business_id}_${item.code}`)

      return { [key]: isChecked(item), ...acc }
    }, {})

    return { ...newField, ...fields }
  }, {})

export const setGlobalFields = (data) =>
  data.reduce((acc, item) => {
    const key = `global_${item.code}`

    return { [key]: isChecked(item), ...acc }
  }, {})
