import services from "services"

export const getEditGroup = async ({ group_id, user_id }) => {
  const group = await services.group.get({ group_id })
  const businesses = await services.user.business({ user_id })

  return {
    group,
    businesses: mergeBusinessGroup(group.businesses, businesses),
  }
}

const mergeBusinessGroup = (business_group, business_user) => {
  const businessGroup = business_group.map((item) => item.business_id)

  return business_user.map((item) => ({
    ...item,
    selected: businessGroup.includes(item.business_id),
  }))
}

export const getEditPage = async ({ manager_id, user_id }) => {
  const [user, userPermissions, profiles, companies, permissions] =
    await Promise.all([
      services.user.get({ user_id }),
      services.user.permissions({ user_id }, false),
      services.user.profiles(),
      services.user.companies({ user_id: manager_id, quantity: "5000" }),
      services.user.permissions({ user_id: manager_id }, false),
    ])

  return {
    user,
    profiles,
    companies,
    business: getAllBusiness(
      permissions.business_permissions,
      userPermissions.business_permissions
    ),
    permissions: mergePermissions(
      permissions.global_permissions,
      userPermissions.global_permissions
    ),
  }
}

const mergePermissions = (manager_permission, user_permission) => {
  const userPermissions = getFormatedPermissions(user_permission)
  return manager_permission.map((item) => ({
    ...item,
    selected: item.code in userPermissions,
  }))
}

const getFormatedPermissions = (permissions) => {
  if (permissions) {
    return permissions.reduce((acc, item) => {
      acc[item.code] = { ...item }
      return acc
    }, {})
  } else {
    return {}
  }
}

const getListBusinessFormated = (list = {}) => {
  const getValuesList = Object.values(list)
  return getValuesList.map((item) => ({
    ...item,
    permissions: item.permissions.map((permission) => ({
      business_id: item.business_id,
      ...permission,
    })),
  }))
}

const checkElementAnotherArray = (
  manager_business_permissions,
  user_business_permissions
) =>
  user_business_permissions.some((item) => {
    if (item.business_id === manager_business_permissions.business_id) {
      return item.permissions.some(
        (permission) => permission.code === manager_business_permissions.code
      )
    }

    return false
  })

const getAllBusiness = (
  manager_business_permissions,
  user_business_permissions
) => {
  const listManagerBusiness = getListBusinessFormated(
    manager_business_permissions
  )
  const listUserBusiness = getListBusinessFormated(user_business_permissions)

  const matchBusiness = listManagerBusiness.map((business) => {
    return {
      ...business,
      permissions: business.permissions.map((permission) => ({
        ...permission,
        selected: checkElementAnotherArray(permission, listUserBusiness),
      })),
    }
  })

  return matchBusiness
}
