import { useCallback, useEffect, useState } from "react"
import auth from "utils/auth"
import helpers from "utils/helpers"
import { getEditPage } from "services/bff/edit"
import message from "utils/message"
import services from "services"
import { values as formValues } from "./form"
import { setBusinessFields, setGlobalFields } from "./utils"
import { navigate } from "gatsby"

const useFormEditUser = ({ userId, currentPage }) => {
  const [values, setValues] = useState(formValues)
  const [profiles, setProfiles] = useState([])
  const [companies, setCompanies] = useState([])
  const [businesses, setBusinesses] = useState([])
  const [permissions, setPermissions] = useState([])

  const managerUser = auth.getUser()

  const fetchPage = useCallback(() => {
    getEditPage({ manager_id: managerUser.user_id, user_id: userId }).then(
      handleFields
    )
  }, [userId, managerUser.user_id])

  const handleFields = ({
    user,
    profiles,
    companies,
    business,
    permissions,
  }) => {
    setProfiles(profiles)
    setCompanies(companies.data)
    setBusinesses(business)
    setPermissions(permissions)
    setValues({
      user_name: user?.user_name,
      document_number: user?.document_number,
      user_email: user?.user_email,
      phone_number: user?.phone_number,
      agent_id: user?.agent_id,
      group_id: user?.group_id,
      profile_id: user?.profile_id,
      postal_code: user?.postal_code,
      ...setGlobalFields(permissions),
      ...setBusinessFields(business),
    })
  }

  const onSubmit = (formData) => {
    services.user.edit({ formData, userId }).then(handleEdit)
  }

  const handleEdit = async ({ error }) => {
    if (!error) {
      message({ notification: "Usuário editado" })
      await helpers.wait(2000)
      return navigate(`${ROUTES.users.path}?current_page=${currentPage}`)
    }
  }

  useEffect(() => {
    fetchPage()
  }, [fetchPage])

  return {
    values,
    profiles,
    companies,
    businesses,
    permissions,
    onSubmit,
  }
}

export default useFormEditUser
