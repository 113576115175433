const isCepValid = (value) => {
  if (!value) value = ""
  const match = value.match(/\d+/gi),
    result = match ? match.join("") : null

  if (result && !/^(.)\1+$/.test(value.replace(/[\D]/, ""))) {
    return result.length === 8
  }

  return false
}

export default isCepValid
