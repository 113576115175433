import name_ from "utils/validators/name"
import cpf_ from "utils/validators/cpf"
import cnpj_ from "utils/validators/cnpj"
import email_ from "utils/validators/email"
import cep_ from "utils/validators/cep"
import phone_ from "utils/validators/phone"

const phone = (value) => {
  return phone_(value, 10, 11)
}

const document = (value) => {
  return cnpj_(value) || cpf_(value)
}

const cnpj = (value) => {
  return cnpj_(value)
}

const cpf = (value) => {
  return cpf_(value)
}

const password = (value) => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-_+={}[\]|\\;:'",.?])[A-Za-z\d!@#$%^&*()\-_+={}[\]|\\;:'",.?]{8,}$/.test(
    value
  )
}

const name = (value) => {
  return name_(value)
}

const email = (value) => {
  return email_(value)
}

const cep = (value) => {
  return cep_(value)
}

export default {
  phone,
  document,
  cnpj,
  cpf,
  name,
  email,
  cep,
  password,
}
