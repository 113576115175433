import Title from "components/Typography/Title"
import If from "components/If"
import classnames from "classnames"
import "./PageHeader.styl"

const PageHeader = ({
  className,
  title,
  subtitle,
  children,
  reverse = false,
  variant = "small",
}) => {
  return (
    <header className={classnames("page-header", className)}>
      <If
        condition={subtitle}
        renderElse={<Title variant={variant}>{title}</Title>}
        renderIf={
          <Title
            className={classnames("page-header__title", {
              "page-header__title--reverse": reverse,
            })}
            variant={variant}
          >
            {title}
            <span className="page-header__subtitle">{subtitle}</span>
          </Title>
        }
      />
      {children}
    </header>
  )
}

export default PageHeader
