const isValidName = (name, nameCount = false) => {
  if (!name) name = ""
  const nameWithoutSpaces = name.trim(),
    blackListedNames = /^(dr|dra|sr|sra|eng)$/,
    validCharRegex =
      /^[a-záéíóúàâêôãõüçñöÁÉÍÓÚÀÂÊÔÃÕÜÇÑÖ][a-z\\'áéíóúàâêôãõüçñöÁÉÍÓÚÀÂÊÔÃÕÜÇÑÖ\s-]+$/gi,
    fullNameArr = nameWithoutSpaces.split(/\s+/),
    isValidNameCount = nameCount
      ? fullNameArr.length === nameCount
      : fullNameArr.length > 1

  if (
    isValidNameCount &&
    nameWithoutSpaces.match(validCharRegex) &&
    !nameWithoutSpaces.match(blackListedNames) &&
    fullNameArr.every((name) => {
      if (!name.match(blackListedNames)) {
        return true
      } else {
        return false
      }
    })
  ) {
    return true
  } else {
    return false
  }
}

export default isValidName
