import "./Switch.styl"

const Switch = ({ name, legend, value, form: { register }, ...props }) => {
  return (
    <div className="form-switch">
      <legend className="form-switch__legend">{legend}</legend>
      <label className="form-switch__control">
        <input
          className="form-switch__checkbox"
          type="checkbox"
          defaultValue={value}
          {...register(name)}
          {...props}
        />
        <span className="form-switch__toggle"></span>
        <span className="form-switch__background"></span>
      </label>
    </div>
  )
}

export default Switch
